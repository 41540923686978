<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          id="overlay-background"
          :show="show"
          rounded="sm"
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between"
            >
              <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
        >
          {{ $t('публиковать') }}
        </b-button> -->
              <div>
                <h3 class="ml-2">
                  {{ GET_ONE_COURSE.title }}
                </h3>
              </div>
              <div
                v-if="
                  $can('manage', 'super_admin') ||
                    $can('manage', 'head_manager') ||
                    $can('manage', 'content_manager')
                "
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mr-2"
                  @click="$router.push(`/course/course-administrations/theme-list/${$route.params.id}/form-types`)"
                >
                  <feather-icon icon="ArrowDownIcon" />
                  {{ $t("courses.form_types_order") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  @click="importChapter"
                >
                  <feather-icon icon="ArrowDownIcon" />
                  {{ $t("courses.import") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  @click="publishCourse"
                >
                  <feather-icon icon="ArrowUpIcon" />
                  {{ $t("courses.publish") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addTheme(null)"
                >
                  <feather-icon icon="PlusIcon" />
                  {{ $t("general.add") }}
                  <!--Добавить button-->
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <draggable
                v-model="list"
                :disabled="$can('manage', 'admin')"
                class="list-group list-group-flush cursor-pointer mt-2"
                tag="ul"
                @change="savePosition($event, true)"
              >
                <div
                  v-for="listItem in list"
                  :key="`key-${listItem.id}`"
                >
                  <b-list-group-item
                    v-b-toggle="`coll${listItem.id}`"
                    tag="li"
                    class="drag-style"
                  >
                    <div class="ml-25">
                      <b-card-text
                        class="font-weight-bold mb-0 d-flex drag-text"
                      >
                        <div>
                          <b-button
                            v-if="
                              $can('manage', 'super_admin') ||
                                $can('manage', 'head_manager') ||
                                $can('manage', 'content_manager')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            class="btn-icon mr-50 p-25"
                            size="sm"
                            @click.stop="addTheme(listItem.position)"
                          >
                            <feather-icon
                              icon="ArrowDownIcon"
                              size="22"
                            />
                          </b-button>
                        </div>
                        <h5 class="text-uppercase p-0 m-0">
                          {{ listItem.title }}
                        </h5>
                        <div class="d-flex align-items-center no-wrap">
                          <b-button
                            v-if="
                              $can('manage', 'super_admin') ||
                                $can('manage', 'head_manager') ||
                                $can('manage', 'content_manager')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="btn-icon mr-50 p-25"
                            size="sm"
                            @click.stop="editChapter(listItem)"
                          >
                            <feather-icon
                              icon="EditIcon"
                              size="22"
                            />
                          </b-button>
                          <b-button
                            v-if="
                              $can('manage', 'super_admin') ||
                                $can('manage', 'head_manager') ||
                                $can('manage', 'content_manager')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon p-25"
                            size="sm"
                            @click.stop="deleteTheme(listItem.id)"
                          >
                            <feather-icon
                              size="22"
                              icon="Trash2Icon"
                            />
                          </b-button>
                        </div>
                      </b-card-text>
                    </div>
                  </b-list-group-item>
                  <b-collapse
                    :id="`coll${listItem.id}`"
                    :visible="true"
                  >
                    <b-card class="mx-2">
                      <b-row>
                        <b-col
                          class="text-right mb-1"
                          cols="12"
                        >
                          <b-button
                            v-if="
                              $can('manage', 'super_admin') ||
                                $can('manage', 'head_manager') ||
                                $can('manage', 'content_manager')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            class="mr-2"
                            @click="importLesson(listItem.id)"
                          >
                            <feather-icon icon="ArrowDownIcon" />
                            {{ $t("courses.import") }}
                          </b-button>
                          <b-button
                            v-if="
                              $can('manage', 'super_admin') ||
                                $can('manage', 'head_manager') ||
                                $can('manage', 'content_manager')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="addLesson(listItem.id, null)"
                          >
                            <feather-icon icon="PlusIcon" />
                            {{ $t("courses.add_lesson") }}
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <dragg-table
                            v-model="listItem.lessons"
                            @edit="editLesson"
                            @addLesson="addLesson(listItem.id, $event)"
                            @delete="deleteLesson"
                            @addContent="
                              (id) =>
                                $router.push(
                                  `/course/course-administrations/theme-list/${$route.params.id}/${id}`
                                )
                            "
                            @addTest="
                              $router.push({
                                name: 'lesson-test-attachments',
                                params: { id: $event },
                              })
                            "
                            @change="savePosition($event, false)"
                          />
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </div>
              </draggable>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <EditChapterModal id="EditChapter" :editData="dataToEdit" :isEdit="true" @add-button="saveTheme" />
    <defoult-add-modal
      id="AddEditTheme"
      :edit-title="$t(titleModaleEdit)"
      :add-title="$t(titleModaleAdd)"
      :edit-data="dataToEdit"
      :description="description"
      :is-edit="true"
      :no-lang="true"
      @add-button="saveTheme"
    />
    <lesson-modal
      :is-edit="lesson_edit"
      :def-lesson="selected_lesson"
      :description="description"
      @saveSuccess="saveTheme"
    />
    <import-chapter-or-lesson-modal
      :chapter-import="chapterImport"
      :clicked-chapter="clickedChapter"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BListGroupItem,
  BCardText,
  BCollapse,
  VBToggle,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import DefoultAddModal from '@/components/DefoultAddModal.vue'
import DraggTable from '@/components/DraggTable.vue'
import lessonModal from './components/modals/addOrEditLesson.vue'
import importChapterOrLessonModal from './components/modals/importChapterOrLessonModal.vue'
import EditChapterModal from './components/modals/EditChapterModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    DefoultAddModal,
    BCardText,
    draggable,
    BListGroupItem,
    BCard,
    BCollapse,
    DraggTable,
    lessonModal,
    importChapterOrLessonModal,
    BOverlay,
    EditChapterModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      lessonData: {},
      editedTheme: {},
      lessonParentId: null,
      isEdit: false,
      isChapter: true,
      table: [],
      chapter: null,
      clickedChapter: null,
      dataToEdit: {},
      titleModaleEdit: 'courses.edit_theme',
      titleModaleAdd: 'courses.add_theme',
      lesson_edit: false,
      selected_lesson: {},
      chapterImport: true,
      show: true,
      description: false,
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE', 'GET_COURSE_CHAPTERS']),
    list: {
      get() {
        return this.GET_COURSE_CHAPTERS
      },
      set(val) {
        this.SET_CHAPTERS(val)
      },
    },
  },
  mounted() {
    // window.addEventListener('scroll', this.onScroll)
    const rootEle = document.documentElement
    const scrollPosition = JSON.parse(sessionStorage.getItem('scrollPosition'))
    this.FETCH_ONE_COURSE({ id: this.$route.params.id }).then(() => {
      this.updateBreadcrumb()
      this.list = this.GET_COURSE_CHAPTERS
      this.show = false
      if (scrollPosition?.id === this.$route.params.id) {
        rootEle.scrollTo({
          top: scrollPosition.position,
          behavior: 'smooth',
        })
      }
    })
  },
  beforeDestroy() {
    this.SET_CHAPTERS([])
    this.SET_ONE_COURSE({})
  },
  methods: {
    ...mapActions('courses', [
      'FETCH_ONE_COURSE',
      'CREATE_CHAPTER',
      'UPDATE_CHAPTER',
      'DELETE_CHAPTER',
      'UPDATE_LESSON',
      'DELETE_LESSON',
      'CREATE_LESSON',
      'UPDATE_LESSON_DRAFT',
    ]),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapMutations('courses', ['SET_CHAPTERS', 'SET_ONE_COURSE']),

    // Chapter
    editTheme(item) {
      this.titleModaleAdd = 'courses.add_theme'
      this.isEdit = true
      this.description = false
      this.isChapter = true
      this.dataToEdit = item
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    editChapter(chapter) {
      this.isEdit = true
      this.isChapter = true
      this.dataToEdit = {
        id: chapter.id,
        title: chapter.title,
        form_type: chapter.form_type,
      }
      this.$nextTick(() => {
        this.$bvModal.show('EditChapter')
      })
    },
    onScroll() {
      this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
      sessionStorage.setItem('scrollPosition', JSON.stringify({ id: this.$route.params.id, position: this.windowTop }))
    },
    // import chapter
    importChapter() {
      this.chapterImport = true
      this.$nextTick(() => {
        this.$bvModal.show('chapter-import-modal')
      })
    },
    // import chapter
    importLesson(id) {
      this.clickedChapter = id
      this.chapterImport = false
      this.$nextTick(() => {
        this.$bvModal.show('chapter-import-modal')
      })
    },

    addTheme(defPosition) {
      this.titleModaleEdit = 'courses.edit_theme'
      if (defPosition !== null) {
        this.dataToEdit = {
          title: '',
          position: defPosition + 1,
        }
      } else {
        this.dataToEdit = {
          title: '',
        }
      }
      this.description = false
      this.isChapter = true
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    saveTheme(data) {
      if (this.isChapter) {
        if (!this.isEdit) {
          const savedData = { course: this.$route.params.id, ...data }
          this.CREATE_CHAPTER(savedData)
            .then(() => {
              this.$_successToast(this.$t('general.added'))
              this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
              this.$bvModal.hide('AddEditTheme')
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        } else {
          this.UPDATE_CHAPTER(data)
            .then(() => {
              this.$_successToast(this.$t('general.edited'))
              this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
              this.$bvModal.hide('AddEditTheme')
              this.$bvModal.hide('EditChapter')
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      } else if (!this.isEdit) {
        const ReturnData = data
        ReturnData.chapter = this.chapter
        this.CREATE_LESSON(ReturnData)
          .then(() => {
            this.$_successToast(this.$t('general.added'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
            this.$bvModal.hide('lesson-part-modal')
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      } else {
        this.UPDATE_LESSON(data)
          .then(() => {
            this.$_successToast(this.$t('general.edited'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
            this.$bvModal.hide('AddEditTheme')
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      }
    },
    deleteTheme(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_CHAPTER(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    // lesson
    addLesson(id, defPosition) {
      // this.titleModaleAdd = 'courses.add_lesson'
      if (defPosition !== null) {
        this.selected_lesson = {
          title: '',
          position: defPosition + 1,
        }
      } else {
        this.selected_lesson = {
          title: '',
        }
      }
      this.description = true
      this.chapter = id
      this.isChapter = false
      this.lesson_edit = false
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('lesson-part-modal')
      })
    },
    editLesson(val) {
      this.titleModaleEdit = 'courses.edit_lesson'
      this.isEdit = true
      this.description = true
      this.dataToEdit = val
      this.isChapter = false
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    deleteLesson(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_LESSON(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    publishCourse() {
      let data = []
      this.GET_COURSE_CHAPTERS.forEach(item => {
        data = data.concat(item.draft_lessons)
      })
      this.$bvModal
        .msgBoxConfirm(this.$t('courses.are_you_sure_want_publish'), {
          title: this.$t('general.pleace_confirm'),
          size: 'sm',
          // buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('general.ok'),
          cancelTitle: this.$t('general.no'),
          // footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
        })
        .then(value => {
          if (value) {
            this.UPDATE_LESSON_DRAFT({ lessons: data, publish: true }) // update lesson draft to publish
              .then(() => {
                this.$_successToast(this.$t('general.published'))
                this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          }
        })
    },
    // others
    savePosition(event, type) {
      const data = {
        id: event.moved.element.id,
        position: event.moved.newIndex,
      }
      if (type) {
        this.UPDATE_CHAPTER(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
          })
      } else {
        this.UPDATE_LESSON(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
            this.FETCH_ONE_COURSE({ id: this.$route.params.id, is_administrator: true })
          })
      }
    },
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(
        this.$route.meta.breadcrumb,
      )
      second.text = this.GET_ONE_COURSE.title
      this.UPDATE_BREADCRUMB([first, second])
    },
    addNewLesson() {},
  },
}
</script>

<style>
.drag-style {
  padding: 1rem 2.8rem 1rem 1rem;
  margin-bottom: 1rem;
}
.drag-text {
  justify-content: space-between;
  align-items: center;
}
</style>
