<template>
  <b-modal
    id="lesson-part-modal"
    centered
    :title="isEdit ? $t('courses.edit_theme') : $t('courses.add_theme')"
    :cancel-title="$t('general.cancel')"
    :ok-title="isEdit ? $t('general.save') : $t('general.add')"
    @ok.prevent="saveClick"
  >
    <validation-observer ref="testPartValidations">
      <b-form-group :label="$t('general.title')">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input v-model="lesson.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        v-if="description"
        :label="$t('general.description')"
        label-for="desc"
      >
        <validation-provider
          #default="{ errors }"
          rules=""
          :name="$t('general.description')"
        >
          <b-form-textarea
            id="desc"
            :placeholder="$t('general.description')"
            rows="3"
            max-rows="8"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group class="d-flex align-item-center">
        <b-form-checkbox
          v-model="lesson.is_test"
          inline
          switch
          @input="changeType"
        />
        {{ $t('tests.test') }}
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,

    BFormTextarea,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    defLesson: {
      type: Object,
      required: false,
      default: () => {},
    },
    description: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      required,
      lesson: {
        title: '',
        is_test: false,
      },
    }
  },
  watch: {
    defLesson() {
      this.lesson = this.defLesson
    },
  },
  methods: {
    async saveClick() {
      const success = await this.$refs.testPartValidations.validate()
      if (!success) return
      this.$emit('saveSuccess', this.lesson)
    },
    changeType(type) {
      if (type) {
        this.lesson.options = {
          report: 'score',
          passing_score: 0,
          result_descriptions: [],
          presentation: 'multiple',
          attempts: null,
        }
      } else {
        this.lesson.options = { attempts: null }
      }
    },
  },
}
</script>

<style>

</style>
