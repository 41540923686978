<template>
  <b-modal
    :id="id"
    :title="editData.id? $t(`${editTitle}`) : $t(`${addTitle}`)"
    size="sm"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t(okTitle)"
    :cancel-title="$t(cancelTitle)"
    centered
    no-close-on-backdrop
    @show="openModal"
    @ok.prevent="submit"
    @cancel="$bvModal.hide(`${id}`)"
  >
    <validation-observer ref="validation">
      <b-form-group
        :label="$t('general.title')"
        label-for="title_input_ru"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input
            id="title_input_ru"
            v-model="data.title"
            :placeholder="$t('general.title')"
            :state="errors.length > 0 ? false : null"
            class="mb-1 "
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('others.form_type')"
        label-for="desc"
      >
        {{ custom_form_type }}
        <div class="d-flex">
          <v-select
            id="desc"
            v-model="data.form_type"
            :clearable="true"
            label="title"
            taggable
            :options="GET_FORM_TYPES.results"
            :reduce="option => option.id"
            style="flex-grow: 1;"
            @option:created="onKeyup"
          />
        </div>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import toast from '@/mixins/toast'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButton,
  },
  mixins: [toast],
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      default: 'DefoultAddModal',
      required: false,
    },
    addTitle: {
      type: String,
      default: 'general.add',
      required: false,
    },
    editTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    okTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    cancelTitle: {
      type: String,
      default: 'general.cancel',
      required: false,
    },
    editData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      data: {
        title: {
          en: '',
          ru: '',
          uz: '',
        },
        price: 0,
      },
      required,
      custom_form_type: '',
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_FORM_TYPES']),
  },
  mounted() {
    this.FETCH_FORM_TYPES({ page_size: 10000 })
  },
  methods: {
    ...mapActions('courses', ['FETCH_FORM_TYPES', 'CREATE_FORM_TYPE']),
    openModal() {
      if (this.isEdit) {
        this.data = this.$_removeObjectConnection(this.editData)
      } else {
        this.data = {
          title: '',
          form_type: null,
        }
      }
    },
    submit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          this.$emit('add-button', this.data)
        } else {
          this.$_errorToast(this.$t('general.no_valid'))
        }
      })
    },
    onKeyup(e) {
      console.log(e)
      this.CREATE_FORM_TYPE(e).then(res => {
        console.log(res)
        this.data.form_type = res.id
      })
    },
  },
}
</script>

<style>

</style>
