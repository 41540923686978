<template>
  <b-modal
    id="chapter-import-modal"
    centered
    :title="$t('courses.import')"
    :cancel-title="$t('general.cancel')"
    :ok-title="$t('general.save')"
    no-close-on-backdrop
    @ok.prevent="importChapter"
    @hidden="clear"
  >
    <validation-observer ref="importValidations">
      <b-row>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('users.course_title')"
          >
            <b-form-group
              :label="$t('users.course_title')"
              label-for="course_title"
            >
              <!-- <v-select
                v-model="course_title"
                :options="GET_COURSES_LIST.results"
                label="title"
                :reduce="el => el.id"
                :placeholder="$t('users.course_title')"
                @input="onCourseSelect"
              /> -->

              <AppSelect
                v-model="course_title"
                :get-option-label="el => el.title"
                :placeholder="$t('users.course_title')"
                :lazy-fetch-data="FETCH_COURSES_LIST_SYNC"
                :options="GET_COURSES_LIST_SYNC"
                :reduce="el => el.id"
                @input="onCourseSelect"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('courses.chapter_title')"
          >
            <b-form-group
              :label="$t('courses.chapter_title')"
              label-for="theme_name"
            >
              <v-select
                v-model="chapter_title"
                label="title"
                :options="GET_CHAPTERS_LIST.results"
                :reduce="el => el.id"
                :placeholder="$t('courses.chapter_title')"
                @input="onChapterSelect"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="!chapterImport"
          cols="12"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('courses.chapter_title')"
          >
            <b-form-group
              :label="$t('courses.lesson_title')"
              label-for="lesson_title"
            >
              <v-select
                v-model="lesson_id"
                :options="GET_LESSONS.results"
                label="title"
                :reduce="el => el.id"
                :placeholder="$t('courses.lesson_title')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import VSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AppSelect from '@/components/AppSelect.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    AppSelect,
    ValidationObserver,
    VSelect,
  },
  props: {
    chapterImport: {
      type: Boolean,
      default: false,
    },
    clickedChapter: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      chapter_title: null,
      course_title: null,
      lesson_id: null,
      required,
      importDate: {
        chapter_ids: [],
        lesson_ids: [],
      },
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_CHAPTERS_LIST', 'GET_COURSES_LIST', 'GET_LESSONS', 'GET_COURSES_LIST_SYNC']),
  },
  methods: {
    ...mapActions('courses', ['FETCH_CHAPTERS_LIST', 'FETCH_COURSES_LIST', 'FETCH_COURSES_LIST_SYNC', 'FETCH_LESSONS', 'IMPORT_CHAPTER_OR_LESSON', 'FETCH_ONE_COURSE']),
    ...mapMutations('courses', ['SET_LESSONS', 'SET_CHAPTERS_LIST']),
    clear() {
      this.course_title = null
      this.chapter_title = null
      this.lesson_id = null
      // this.SET_LESSONS({ results: [] })
      // this.SET_CHAPTERS_LIST({ results: [] })
    },
    onCourseSelect(e) {
      this.chapter_title = null
      this.lesson_id = null
      this.$nextTick(() => {
        this.FETCH_CHAPTERS_LIST({ course: e, page_size: 1000 })
      })
    },
    onChapterSelect(e) {
      this.lesson_id = null
      // this.SET_CHAPTERS_LIST({ results: [] })
      this.$nextTick(() => {
        this.FETCH_LESSONS({ chapter: e, page_size: 1000 })
      })
    },
    importChapter() {
      this.$refs.importValidations.validate().then(success => {
        if (!success) return
        const data = {}
        this.$set(data, 'course_id', this.$route.params.id)
        this.$set(data, 'chapter_ids', [this.chapter_title])
        if (this.chapterImport) {
          this.$set(data, 'chapter_ids', [this.chapter_title])
        } else {
          this.$set(data, 'chapter_ids', [this.clickedChapter])
          this.$set(data, 'lesson_ids', [this.lesson_id])
        }
        this.IMPORT_CHAPTER_OR_LESSON(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
            this.$bvModal.hide('chapter-import-modal')
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      })
    },
  },
}
</script>

<style>

</style>
