var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_vm._l((_vm.fields),function(i){return [(i.condition)?_c('th',{key:i.key,class:i.thClass,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t(i.label))+" ")]):_vm._e()]})],2)]),_c('draggable',{attrs:{"disabled":_vm.$can('manage', 'admin'),"tag":"tbody"},on:{"input":_vm.onInput,"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.Vmodel),callback:function ($$v) {_vm.Vmodel=$$v},expression:"Vmodel"}},_vm._l((_vm.Vmodel),function(item){return _c('tr',{key:("tablle" + (item.id))},[(
          _vm.$can('manage', 'super_admin') ||
            _vm.$can('manage', 'head_manager') ||
            _vm.$can('manage', 'content_manager')
        )?_c('td',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-50 p-25",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.$emit('addLesson', item.position)}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon","size":"22"}})],1)],1):_vm._e(),_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.is_test ? _vm.$t("courses.test") : _vm.$t("courses.content"))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.content_count)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.question_count)+" ")]),(
          _vm.$can('manage', 'super_admin') ||
            _vm.$can('manage', 'head_manager') ||
            _vm.$can('manage', 'content_manager')
        )?_c('td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center no-wrap"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-50 p-25",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.attachClick(item)}}},[_c('feather-icon',{attrs:{"icon":"FilePlusIcon","size":"22"}})],1),(
              _vm.$can('manage', 'super_admin') ||
                _vm.$can('manage', 'head_manager') ||
                _vm.$can('manage', 'content_manager')
            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-50 p-25",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"22"}})],1):_vm._e(),(
              _vm.$can('manage', 'super_admin') ||
                _vm.$can('manage', 'head_manager') ||
                _vm.$can('manage', 'content_manager')
            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon p-25",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"Trash2Icon"}})],1):_vm._e()],1)]):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }